import React from 'react';
import AboutSection from '../sections/AboutSection';

const AboutPage = () => {
    return (
        <div>
            <AboutSection />
        </div>
    );
};

export default AboutPage; 