import React from 'react';
import ContactSection from '../sections/ContactSection';

const ContactPage = () => {
    return (
        <div>
            <ContactSection />
        </div>
    );
};

export default ContactPage; 